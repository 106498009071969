import * as React from "react";
import Navbar from "../components/nav-bar";
import Footer from "../components/footer";
import Banner from "../components/banner";
import SEO from "../components/seo";
import { Row, Container, Col } from "react-bootstrap";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
const ProblemsWeSolvePage = () => {
  const { strapiProblems } = useStaticQuery(pageQuery);
  const { content } = strapiProblems;
  return (
    <div className="outer-container">
      <Navbar></Navbar>
      <SEO
        title={`Problems we solve - Securing money for your business, We are here to serve the Irish SME | Ireland | Best Ireland Finance Services`}
        description={
          "We solve your financial problems in your business, securing money for your business, providing funding options and providing banking and non-banking borrowing.We are here to serve the Irish SME | Ireland | Best Ireland Finance Consultancy services. "
        }
      ></SEO>
      <Container fluid className="p-0 overflow-hidden">
        <Banner title="Problems We Solve" backgroundClass="home-img"></Banner>
        <Row className="article p-5">
          <Col>
            <ReactMarkdown>{content}</ReactMarkdown>
          </Col>
        </Row>
        <Footer></Footer>
      </Container>
    </div>
  );
};

export default ProblemsWeSolvePage;

export const pageQuery = graphql`
  query ProblemsPageDataQuery {
    strapiProblems {
          content
    }
  }
`;
